import React from 'react'
import { Link, graphql } from 'gatsby'
import './PostList.scss'
import RenderContent from '../../RenderContent'
import Pagination from '../Pagination'
import { Image } from '../../Image'
import { FaChevronRight } from '../../../images/icons/FaChevronRight'
import PostListItem from '../PostListItem'
import { PostListNavigation } from '../PostListNavigation'

export default class IndexPage extends React.Component {
  render() {
    const {
      posts,
      title,
      pageContext,
      categoryFilter,
      pathPrefix,
      pathSuffix = '',
      siteMetadata,
    } = this.props
    return (
      <>
        <PostListNavigation
          pathPrefix={pathPrefix}
          categoryFilter={categoryFilter}
        />
        <section className="post-list">
          <div className="outer">
            <div className="wrap">
              <div className="inner">
                <div className="list">
                  {posts &&
                    posts.map(
                      (post, inedx) =>
                        post.node.slug !== 'gatsby' && (
                          <PostListItem
                            key={inedx}
                            data={post.node}
                            siteMetadata={siteMetadata}
                            pathPrefix={pathPrefix}
                          />
                        )
                    )}
                </div>
                <Pagination
                  pageContext={pageContext}
                  pathPrefix={`/${pathPrefix}/${pathSuffix}`}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    categories {
      id
      name
      slug
    }
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
    path
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
